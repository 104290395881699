<template>
  <q-card style="width: 600px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        <small class="q-ml-sm text-size-sm">
          详细
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <div class="col-5">
          <q-input
            label="名称"
            dense
            outlined
            v-model="PdmBannerEntity.BANNER_NAME"
          />
        </div>
        <div class="col-6">
          <select_hospInfo
            :dense="true"
            :readonly="hospID == 0 ? false : true"
            outlined
            label="选择机构"
            v-model="PdmBannerEntity.HOSPID"
            v-model:valueCode="PdmBannerEntity.HOSPID"
          />
        </div>

        <div class="col-12">
          <div class="kuang_pic">
            <q-btn
              color="primary"
              label="上传图片"
              @click="onFiles()"
              class="q-mt-sm"
            />

            <q-img
              v-if="PdmBannerEntity.BANNER_TU"
              style="max-width: 90%; max-height: 200px"
              :src="ImagesUrl + 'UploadImage/' + PdmBannerEntity.BANNER_TU"
            >
            </q-img>
          </div>
        </div>
      </div>
      <q-uploader
        label="Auto Uploader"
        ref="Files"
        v-show="false"
        auto-upload
        :url="ImagesUrl + 'api/PDM_BANNER/upload'"
        accept=".jpg, image/*"
        @uploaded="uploaded"
        multiple
        :headers="[{ name: 'BANNER_TU', value: PdmBannerEntity.BANNER_TU }]"
      />
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import select_hospInfo from "@/views/pdm/comm/hospInfo_select.vue";
export default {
  components: {
    select_hospInfo,
  },
  props: {},
  computed: {
    ...mapGetters("appUser", ["hospID", "ImagesUrl"]),
    ...mapState("PdmBanner", [
      "PdmBannerShowDlg",
      "PdmBannerEntity",
      "PdmBannerList",
    ]),
  },
  data() {
    return {};
  },
  mounted() {
    this.PdmBannerEntity.HOSPID = this.hospID;
  },
  methods: {
    ...mapActions("PdmBanner", ["actUpdatePdmBanner", "actGetPdmBannerList"]),

    onOK() {
      this.actUpdatePdmBanner({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: this.PdmBannerEntity,
      }).then((res) => {
        if (res.Code == 0) {
          setTimeout(()=> {
            this.$emit("onLoadData");
          }, 200);
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
    uploaded(info) {
      this.PdmBannerEntity.BANNER_TU = JSON.parse(
        info.xhr.responseText
      ).ReturnSign;
      
    },
    onFiles() {
      this.$refs.Files.pickFiles();
      this.$refs.Files.reset(); //将上传器重置为默认值；清空队列，中止当前上传
    },
  },
};
</script>
